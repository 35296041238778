import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'

import Section from './section'
import SubscribeForm from './subscribe-form'

const SubscribeFormCta = ({ children }) => (
  <Section className="section section-hazelnut py-5">
    <Container>
      <Row className="justify-content-around">
        <Col xs={12} md={9} lg={7} xl={6}>
          {children}
          <SubscribeForm />
        </Col>
      </Row>
    </Container>
  </Section>
)

SubscribeFormCta.propTypes = {
  children: PropTypes.node,
}

export default SubscribeFormCta
