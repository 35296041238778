import React from 'react'
import PropTypes from 'prop-types'

const InfoBox = ({ children, mb }) => {
  const className = ['info-box']
  if (mb) className.push(`mb-${mb}`)

  return (
    <div className={className.join(' ')}>
      {children}
    </div>
  )
}

InfoBox.propTypes = {
  mb: PropTypes.number,
}

InfoBox.Head = ({ children }) => (
  <h2 className="info-box-head">
    {children}
  </h2>
)

InfoBox.Body = ({ children }) => (
  <div className="info-box-body">
    {children}
  </div>
)

InfoBox.Footer = ({ children }) => (
  <footer className="info-box-footer">
    {children}
  </footer>
)

export default InfoBox
