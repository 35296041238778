import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Section from '../components/section'
import Listing from '../components/listing'
import InfoBox from '../components/info-box'
import StarRating from '../components/star-rating'
import SubscribeCta from '../components/subscribe-cta'

const ReviewsListTemplate = ({ data }) => {
  const reviews = data.allMarkdownRemark.nodes
  const canonicalUrl = `${data.site.siteMetadata.siteUrl}/reviews/`

  const metaDesc = (
    `What are the best s’mores treats and ingredients? Join me on my quest ` +
    `to find the best of the s’mores snacks, ingredients, drinks, clothing, ` +
    `and toys.`
  )

  const socialImage = 'https://www.smorescout.com/images/social/reviews-summary.png'
  const socialImageAlt = `S’mores ratings on a scale of 1 to 5`

  return (
    <Layout>
      <Seo
        title="S’mores Product Reviews"
        description={metaDesc}
        canonicalUrl={canonicalUrl}
        meta={[
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:image',
            content: socialImage,
          },
          {
            property: 'og:image:alt',
            content: socialImageAlt,
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: socialImage,
          },
          {
            name: 'twitter:image:alt',
            content: socialImageAlt,
          },
        ]}
      />

      <Section className="pb-5">
        <Container>
          <Row className="justify-content-around">
            <Col span={12} lg={9} xl={12} className="mb-2">
              <Breadcrumb>
                <Breadcrumb.Item
                  href="/"
                  linkProps={{ className: "breadcrumb-link" }}
                >
                  Home
                </Breadcrumb.Item>
              </Breadcrumb>

              <h1>
                S’mores Product Reviews
              </h1>
            </Col>
          </Row>

          <Row className="justify-content-around">
            <Col xs={12} lg={9} xl={8}>
              <p className="lead mb-5">
                What are the best s’mores treats and ingredients? Join me on my
                quest to find the best of the s’mores snacks, ingredients,
                drinks, clothing, and toys.
              </p>

              {reviews.map(review => {
                const slug = review.fields.slug

                const {
                  title,
                  rating,
                  summary,
                  unavailable,
                  images,
                } = review.frontmatter

                const { overall } = rating

                return (
                  <div key={review.frontmatter.title} className="mb-4">
                    <Listing
                      slug={slug}
                      title={title}
                      rating={overall}
                      summary={summary}
                      unavailable={unavailable}
                      image={images[0]}
                    />
                  </div>
                )
              })}
            </Col>

            <Col as="aside" xs={12} lg={9} xl={4}>
              <InfoBox>
                <InfoBox.Body>
                  <h2 className="h4 mb-3">
                    We're just getting started here!
                  </h2>

                  <div className="mb-3">
                    <StarRating rating={3} size="large" theme="dark" />
                  </div>
                  <p>
                    Hang tight, s’more reviews are coming.
                  </p>
                  <p>
                    Have an idea? Email me at
                    {' '}
                    <strong>
                      <a href="mailto:chris@smorescout.com">
                        tips@smorescout.com
                      </a>
                    </strong>
                  </p>
                </InfoBox.Body>
              </InfoBox>
            </Col>
          </Row>
        </Container>
      </Section>

      <SubscribeCta>
        <h2 className="mb-3">
          Get s’more reviews in your inbox
        </h2>
      </SubscribeCta>
    </Layout>
  )
}

export const query = graphql`
  query Reviews {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allMarkdownRemark(
      filter: { fields: { content_type: { eq: "reviews" }}}
      sort: {fields: frontmatter___published_on, order: DESC}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          summary
          rating {
            overall
          }
          unavailable
          images: image_gallery {
            thumb: file {
              childImageSharp {
                gatsbyImageData(width: 247, height: 247, transformOptions: {
                  cropFocus: CENTER
                })
              }
            }
            alt
          }
        }
      }
    }
  }
`

export default ReviewsListTemplate
