import React from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import StarRating from './star-rating'
import UnavailableBadge from './unavailable-badge'

const Listing = ({
  image,
  rating,
  slug,
  summary,
  theme,
  title,
  unavailable,
}) => {
  function containerClickHandler() {
    navigate(slug)
  }

  const className = ['listing']

  if (theme) {
    className.push(`listing-theme-${theme}`)
  }

  return (
    <div
      className={className.join(' ')}
      role="link"
      tabIndex={0}
      onClick={containerClickHandler}
      onKeyPress={containerClickHandler}
    >
      <div className="listing-photo">
        <GatsbyImage
          image={image.thumb.childImageSharp.gatsbyImageData}
          alt={image.alt}
          className="listing-image"
        />
      </div>

      <div className="listing-content">
        <h2 className="listing-title">
          <Link to={slug} className="listing-link">
            {title}
          </Link>
        </h2>

        {rating ? (
          <ul className="listing-meta">
            <li className="listing-meta-item">
              Rating: <StarRating rating={rating} theme="dark" />
            </li>

            {unavailable ? (
              <li className="listing-meta-item">
                <UnavailableBadge label={unavailable} />
              </li>
            ) : ''}
          </ul>
        ) : ''}

        <p className="listing-summary">
          {summary}
        </p>
      </div>
    </div>
  )
}

Listing.defaultProps = {
  theme: 'nebula-light',
  orientation: 'horizontal',
}

Listing.propTypes = {
  rating: PropTypes.number,
  slug: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  theme: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Listing
