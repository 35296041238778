import React from 'react'
import PropTypes from 'prop-types'

import Star from '../images/star.svg'
import InactiveStar from '../images/star-inactive.svg'
import InactiveStarDark from '../images/star-inactive-dark.svg'

const StarRating = ({ rating, size, theme }) => {
  const stars = []
  let className = ['star-rating']

  for (let i = 0; i < 5; i++) {
    stars.push({
      key: 'star-' + Date.now() + '-' + i,
      isActive: i < rating,
    })
  }

  if (size === 'large') {
    className.push('star-rating-lg')
  }

  return (
    <div className={className.join(' ')}>
      {stars.map(star => {
        if (star.isActive) {
          return <Star key={star.key} />
        } else if (theme === 'dark') {
          return <InactiveStarDark key={star.key} />
        } else {
          return <InactiveStar key={star.key} />
        }
      })}
    </div>
  )
}

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
  size: PropTypes.string,
  theme: PropTypes.string,
}

export default StarRating
